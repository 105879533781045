import React from 'react';

import s from './search.module.css';

export default ({ onChangeSearch, placeholder }) => {

  const handleSearch = (event) => {
    onChangeSearch(event.target.value);
  }

return (
  <div className={s.searchWrapper}>
    <div className={s.searchInput}>
      <input
        className={s.input}
        type="text"
        onChange={handleSearch}
        placeholder={placeholder}
      />
    </div>
  </div>
  )
};
