
import React, { useState, useEffect } from 'react';

import Container from '../components/container';
import PageHeader from '../components/pageHeader';
import PersonList from '../components/person-list';
import Search from '../components/search';
import SearchResult from '../components/searchResult';

export default  ( { persons, title, subTitle, searchLabel } ) => {

  const [searchedPersonList, SetSearchedPersonList] = useState(persons);
  const [isSearching, SetIsSearching] = useState(false);

  const onChangeSearch = ( searchString ) => {
    SetIsSearching(searchString !== '');
    SetSearchedPersonList(persons.filter(item => item.node.name.toLowerCase().includes(searchString.toLowerCase())));
  }

  return (
    <Container belowHeader>


     {isSearching ?
      <SearchResult>
        {searchedPersonList.length === 0 ?
          <span>Því miður fannst engin starfsmaður.</span>
        :
          <PersonList persons={searchedPersonList} moblieSlider />
        }
      </SearchResult>
     :
        <SearchResult>
          <PageHeader
            center
            big
            title={title}
            subTitle={subTitle}
          />
        </SearchResult>
     }
      <Search
        onChangeSearch={(searchString) => onChangeSearch(searchString)}
        placeholder={searchLabel}
      />
    </Container>
  );

}
