import React, { useEffect, useState } from 'react';
import { graphql, navigate } from 'gatsby';
import get from 'lodash/get';
import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import Loading from '../components/loading';
import PersonsSearchList from '../containers/personsSearchList';
import PersonsFilterList from '../containers/personsFilteredList';

const netlifyIdentity = require("netlify-identity-widget");

export default ( { data, location} ) => {

  useEffect(() => {

    netlifyIdentity.init({container: "#widgets"});

    // get Data
    const persons = data.allContentfulPerson.edges;
    const companies = data.allContentfulCompany.edges;

    // find company logged in
    const currentEmail = (netlifyIdentity.currentUser() && netlifyIdentity.currentUser().email); // || 'h@heiddi.com';
    const currentCompany = companies.find(item => item.node.email === currentEmail);
    SetCurrentCompanyNode(currentCompany.node);

    // find staff in company
    const currentCompanyName = currentCompany && currentCompany.node.name;

    SetCurrentCompanyName(currentCompanyName);
    const currentCompanyPersons = persons.filter(item => ( item.node.company && item.node.company.name) === currentCompanyName);
    SetCurrentCompanyPersons(currentCompanyPersons);

    // set logo of company
    const logoUrl = currentCompany && currentCompany.node.logo.file.url;
    SetLogoUrl(logoUrl);

    // display page
    const hasPermission = !!currentEmail;

    if (hasPermission) {
      SetLoading(false);
    } else {
      navigate('/404');
    }
  }, [data, netlifyIdentity]);

  const changeListType = () => {
    if (listType === 'search') {
      SetListType('all');
      SetListTypeText('Starfsmannaleit');
    } else {
      SetListType('search');
      SetListTypeText('Starfsfólk');
    }
  };

  const [listType, SetListType] = useState('search');
  const [listTypeText, SetListTypeText] = useState('Starfsfólk');
  const [logoUrl, SetLogoUrl] = useState('');
  const [loading, SetLoading] = useState(true);
  const [currentCompanyPersons, SetCurrentCompanyPersons] = useState([]);
  const [currentCompanyName, SetCurrentCompanyName] = useState([]);
  const [currentCompanyNode, SetCurrentCompanyNode] = useState([]);

  const pageTitle = `Starfsfólk ${currentCompanyNode.name}`;

  return (
    <Layout location={location} logo={logoUrl} changeListType={changeListType} changeListTypeText={listTypeText}>
      <Helmet title={pageTitle} />

      {loading ?
        <Loading />
        :
        (listType === 'search') ?
          <PersonsSearchList
            persons={currentCompanyPersons}
            title={currentCompanyNode.textTheme && currentCompanyNode.textTheme.mainTitle || `Velkominn til ${currentCompanyNode.name}`}
            subTitle={currentCompanyNode.textTheme && currentCompanyNode.textTheme.mainSubtitle}
            searchLabel={currentCompanyNode.textTheme && currentCompanyNode.textTheme.searchLabel || 'Hvern viltu hitta?'}
          />
          :
          <PersonsFilterList persons={currentCompanyPersons} />
      }
    </Layout>
  );

}

export const pageQuery = graphql`
  query CompanyQuery {

    allContentfulPerson {
      edges {
        node {
          name
          handle
          department
          company {
            name
          }
          image {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulCompany {
      edges {
        node {
          email
          name
          logo {
            file {
              url
            }
          }
          textTheme {
            mainTitle
            mainSubtitle
            searchLabel
          }
        }
      }
    }
  }
`
