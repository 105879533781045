import { sub } from 'date-fns'
import React from 'react'

import s from './pageHeader.module.css'

export default ({ children, big, center, title, subTitle }) => (
  <div className={s.pageHeader}>
    <div className={[s.headers, center ? s.center : ''].filter(x => !!x).join(' ')}>
      {title &&
        <h1 className={[s.title, big ? s.big : '',].filter(x => !!x).join(' ')}>
          {title}
        </h1>
      }
      {subTitle &&
        <h2 className={[s.subtitle, big ? s.big : '',].filter(x => !!x).join(' ')}>
          {subTitle}
        </h2>
      }
    </div>
    {children}
  </div>
)
