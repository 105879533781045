import React from 'react';
import PersonPreview from './person-preview';
import { getDistance } from 'geolib';

import s from './person-list.module.css';

class PersonList extends React.Component {

  render() {

    const { persons, moblieSlider } = this.props;

    return (
      <ul className={[s.personList, moblieSlider ? s.moblieSlider : ''].filter(x => !!x).join(' ')}>
        {persons
          .sort((a, b) => (a.node.name.localeCompare(b.node.name)))
          .map(({ node }) => {

          return (
            <li className={s.personListItem} key={node.handle}>
              <PersonPreview person={node} />
            </li>
          )
        })}
      </ul>
    )
  }
}

export default PersonList
