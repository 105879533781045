import React from 'react'

import s from './searchResult.module.css'


export default ({ children }) => (
  <div className={s.searchResult}>
    {children}
  </div>
)
