import React from 'react';

import ButtonWrapper from '../components/button-wrapper';
import Button from '../components/button';
import s from './filter.module.css';

export default ({ departmentsList, onChangeDepartment, selectedDepartment }) => (

  <div className={s.filterWrapper}>

    {departmentsList && <ButtonWrapper>
      {departmentsList.map(department => {
        return(
          <Button
            key={department}
            type="button"
            selected={selectedDepartment === department}
            onClick={() => onChangeDepartment(department)}
          >
            {department}
          </Button>
        )
      })}
    </ButtonWrapper>}
  </div>
);
