import React from 'react'
import { Link } from 'gatsby'

import { getPlacementImg } from '../utils/img-helpers';

import s from './person-preview.module.css'

class PersonPreview extends React.Component {

  render() {
    const { person } = this.props;

    return (
      <div className={s.preview}>
        <Link
          className={s.link}
          to={`/starfsfolk/${person.handle}`}
        >
          <div
            className={s.previewImage}
            style={{ backgroundImage: `url(${person.image ? person.image.file.url : getPlacementImg(person.type, 306)})` }}
          />
          <div className={s.previewContent}>

            <h3 className={s.previewTitle}>
              {person.name}
            </h3>

          </div>

        </Link>
      </div>
    )
  }
}

export default PersonPreview
