
import React, { useState, useEffect } from 'react';

import Container from '../components/container';
import PersonList from '../components/person-list';
import Filter from '../components/filter';

export default  ( { persons } ) => {

  const [filteredPersonList, SetFilteredPersonList] = useState(persons);
  const [selectedDepartment, SetSelectedDepartment] = useState('');

  const onChangeDepartment = ( department ) => {
    if (selectedDepartment === department) {
      SetSelectedDepartment('');
      filterPersonList('');
    } else {
      SetSelectedDepartment(department);
      filterPersonList(department);
    }
  }

  const filterPersonList = (selectedDepartment) => {
    SetFilteredPersonList(persons.filter(item => (item.node.department === selectedDepartment || selectedDepartment === '')));
  }

  const departmentsList = persons.map(item => item.node.department).filter((item, i, a) => a.indexOf(item) === i);

  const personsList = (selectedDepartment === '') ? persons : filteredPersonList;

  return (
    <Container belowHeader>
      <Filter
        departmentsList={departmentsList}
        onChangeDepartment={(department) => onChangeDepartment(department)}
        selectedDepartment={selectedDepartment}
      />
      {personsList.length === 0 ?
        <span>Því miður fannst engin starfsmaður.</span>
      :
        <PersonList persons={personsList} />
      }
    </Container>
  );

}
